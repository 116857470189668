import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FileSystemDirectoryEntry, FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { checkPermission } from 'src/app/utils/common';
import { SnackbarService } from 'src/app/utils/services/snackbar/snackbar.service';

@Component({
  selector: 'app-bulk-upload',
  templateUrl: './bulk-upload.component.html',
  styleUrls: ['./bulk-upload.component.scss']
})
export class BulkUploadComponent implements OnInit {
  @Input() loading;
  @Output() uploadBulkImages = new EventEmitter();
  constructor(
    private msg: SnackbarService
  ) { }

  ngOnInit() {
  }

  dropped(files: NgxFileDropEntry[]) {
    let formatedFile = [];
    // let permission = this.permission ? this.permission : 'documents.update';
    if (checkPermission('documents.update')) {
      for (const [i, droppedFile] of files.entries()) {

        // Is it a file?
        if (droppedFile.fileEntry.isFile) {
          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
          fileEntry.file((file: File) => {
            console.log(file);

            // Here you can access the real file
            formatedFile.push(file);
            if (i == (files.length - 1)) {
              this.uploadBulkImages.emit(formatedFile);
            }
          });
        } else {
          // It was a directory (empty directories are added, otherwise only files)
          const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        }
      }
    }
    else {
      this.msg.openSnackBar(`You don't have permission to upload documents`);
    }
  }

}
