import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileName'
})
export class FileNamePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    let fileNames = [{
      key: 'workingSheet',
      name: 'Working Sheet'
    }, {
      key: 'challan',
      name: 'Challan'
    }, {
      key: 'acknowledgement',
      name: 'Acknowle- dgement'
    }, {
      key: 'fvu_file',
      name: 'FVU File'
    }, {
      key: 'certificate',
      name: 'Certificate'
    }];

    let file = fileNames.find(element => {
      return element.key == value;
    })
    return file ? file.name : value;
  }

}
