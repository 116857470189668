import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CommonService } from 'src/app/utils/services/common/common.service';
import { base64 } from 'src/app/utils/common';
import { SnackbarService } from 'src/app/utils/services/snackbar/snackbar.service';

export interface MatData {
  client: any,
  doc: any,
}

@Component({
  selector: 'app-assign-members',
  templateUrl: './assign-members.component.html',
  styleUrls: ['./assign-members.component.scss']
})
export class AssignMembersComponent implements OnInit {
  form: any;
  loading: boolean;
  allMembers: any[];
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<AssignMembersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MatData,
    public common: CommonService,
    private msg: SnackbarService

  ) {
  }

  ngOnInit() {
    this.createForm();
    this.getMembers();

  }

  createForm() {
    this.form = this.fb.group({
      assignedMembers: []
    })
  }

  getMembers() {
    let params = { role: 'employee' };
    params = base64(params);
    this.common.getUsers({ filter: params }).subscribe(res => {
      this.allMembers = [...res['records']];
      if (this.data.client) {
        if (this.data['client']['assignedMembers'] && this.data['client']['assignedMembers'].length > 0) {
          this.fillMember(this.data['client']['assignedMembers']);
        }
      }
      if (this.data.doc) {
        if (this.data['doc']['assignedMembers'] && this.data['doc']['assignedMembers'].length > 0) {
          this.fillMember(this.data['doc']['assignedMembers']);
        }
      }
    })
  }

  submit(data) {
    this.loading = true;
    let assignedMembers = [...data.assignedMembers];
    if (this.data['client']) {
      this.common.assignMemberToClient(this.data['client']['_id'], { assignedMembers: assignedMembers }).subscribe(res => {
        this.loading = false;
        this.msg.openSnackBar('Assigned member is updated.');
        this.dialogRef.close(true);
      }, (err) => {
        this.loading = false;
        this.msg.openSnackBar(err);
      })
    }
    else {
      this.common.updateDocument(this.data['doc']['_id'], { assignedMembers: assignedMembers }).subscribe(res => {
        this.loading = false;
        this.msg.openSnackBar('Assigned member is updated.');
        this.dialogRef.close(true);
      }, (err) => {
        this.loading = false;
        this.msg.openSnackBar(err);
      })
    }
  }

  close() {
    this.dialogRef.close();
  }

  removeMember(member) {
    let members = this.form.value.assignedMembers;
    let index = members.indexOf(member);

    if (index >= 0) {
      members.splice(index, 1);
      this.form.controls.assignedMembers.setValue(members);
    }
  }

  fillMember(data) {
    let alreadyAssigned = data.map(member => {
      return member._id;
    })
    this.form.controls.assignedMembers.setValue(alreadyAssigned);
  }
}
