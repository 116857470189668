import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'docName'
})
export class DocNamePipe implements PipeTransform {

  transform(value: any, args): any {
    let newVal = value.replace(`${args}_`, '');
    return newVal;
  }

}
