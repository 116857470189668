import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'assigned'
})
export class AssignedPipe implements PipeTransform {

  transform(value: any, args): any {
    let index = value.findIndex(element => {
      return element._id == args;
    })
    if (index >= 0) {
      return value[index].name;
    }
    else {
      return null;
    }
  }

}
