import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { CommonService } from 'src/app/utils/services/common/common.service';
import { SnackbarService } from 'src/app/utils/services/snackbar/snackbar.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { allFinancialYear, getMonthName, base64, clean, validationError, removeExtension } from 'src/app/utils/common';

export interface MatData {
  payment: any
}

@Component({
  selector: 'app-add-reminder',
  templateUrl: './add-reminder.component.html',
  styleUrls: ['./add-reminder.component.scss']
})
export class AddReminderComponent implements OnInit {
  form;
  allFinancialYear = allFinancialYear();
  allMonths = getMonthName();
  allClients: any[];
  edit: boolean;
  loading: boolean;
  fileLoading = {
    invoice: false
  }
  bulkLoading: boolean;
  constructor(
    private fb: FormBuilder,
    public common: CommonService,
    private msg: SnackbarService,
    private dialogRef: MatDialogRef<AddReminderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MatData,
  ) { }

  ngOnInit() {
    this.createForm();
    this.getAllClients();
    if (this.data['payment']) {
      this.edit = true;
      this.fillForm(this.data['payment']);
    }
  }

  createForm() {
    this.form = this.fb.group({
      client: ['', [Validators.required]],
      dueDate: ['', [Validators.required]],
      status: ['pending'],
      // file: ['', [Validators.required]],
      // preview: [''],
      invoice: this.fb.array([
        // this.fb.group({
        //   file: [''],
        //   preview: [''],
        //   name: [''],
        //   loading: [false]
        // })
      ]),
      paidDate: [''],
      // month: [''],
      // type: [''],
      financialYear: [this.allFinancialYear[this.allFinancialYear.length - 1], [Validators.required]],
      // amount: ['']
    })
  }

  getAllClients() {
    let params = { role: 'client' };
    params = base64(params);
    this.common.getUsers({ filter: params }).subscribe(res => {
      this.allClients = res['records'];
    }, (err) => {

    })
  }


  close() {
    this.dialogRef.close();
  }

  uploadImage(data, control, val) {
    this.setFileLoading(val, true);
    let formData = new FormData();
    formData.append('payload', data.image);

    this.common.uploadImage(formData, 'hma-docs').subscribe(res => {
      this.setFileLoading(val, false);
      if (res[0]) {
        control.controls.file.setValue(res[0]['file']['location']);
        control.controls.preview.setValue(res[0]['file']['location']);
        if (control.controls.name) {
          control.controls.name.setValue(removeExtension(res[0]['file']['name']));
        }
      }
    }, (err) => {
      this.setFileLoading(val, false);
    })
  }

  setFileLoading(val, setVal) {
    this.form.controls.invoice.controls[val].controls.loading.setValue(setVal);
  }

  addReminder(data) {
    if (this.form.invalid) {
      validationError(this.form);
    }
    else {
      this.loading = true;
      console.log(data);
      let formData = clean({ ...data });
      formData.invoice = data.invoice.map(element => {
        return {
          file: element.file,
          preview: element.preview,
          name: element.name,
        }
      })
      if (this.edit) {
        this.common.updatePaymentReminder(this.data['payment']['_id'], formData).subscribe(res => {
          this.loading = false;
          this.msg.openSnackBar('Payment Reminder is updated successfully');
          this.dialogRef.close(true);
        }, (err => {
          this.loading = false;
          this.msg.openSnackBar(err);
        }))
      }
      else {
        delete formData.paidDate;
        delete formData.status;
        this.common.addPaymentReminder(formData).subscribe(res => {
          this.loading = false;
          this.msg.openSnackBar('Payment Reminder is added successfully');
          this.dialogRef.close(true);
        }, (err => {
          this.loading = false;
          this.msg.openSnackBar(err);
        }))
      }
    }
  }

  fillForm(data) {
    console.log(data);
    this.form.controls.client.setValue(data.client ? data.client._id : '');
    this.form.controls.dueDate.setValue(data.dueDate);
    // this.form.controls.file.setValue(data.file);
    // this.form.controls.preview.setValue(data.preview);
    this.form.controls.financialYear.setValue(data.financialYear);
    this.form.controls.status.setValue(data.status);
    this.form.controls.paidDate.setValue(data.paidDate ? data.paidDate : new Date());
    if (data.invoice && data.invoice.length > 0) {
      this.fillInvoice(data.invoice);
    }
  }

  download(file, name) {
    this.common.download(file, 'hma-docs', name);
  }

  uploadBulkImages(files) {
    this.bulkLoading = true;
    let formData = new FormData();
    console.log(files);
    for (let file of files) {
      console.log(file);
      formData.append('payload', file);
    }

    this.common.uploadImage(formData, 'hma-docs').subscribe(res => {
      this.bulkLoading = false;
      let docs = res.map(element => {
        return {
          file: element['file']['location'],
          preview: element['file']['location'],
          name: removeExtension(element['file']['name'])
        }
      })
      this.createDocumentBoxes(docs);
    }, (err) => {
      this.bulkLoading = false;
    })
  }

  createDocumentBoxes(docs) {
    for (let doc of docs) {
      this.addFolderFile(doc);
    }
  }

  addFolderFile(invoice?) {
    let control = <FormArray>this.form.controls.invoice;
    control.push(
      this.fb.group({
        file: [invoice ? invoice.file : ''],
        preview: [invoice ? invoice.preview : ''],
        name: [invoice ? invoice.name : ''],
        loading: [false]
      })
    );
  }

  deleteFolderFile(index) {
    let control = <FormArray>this.form.controls.invoice;
    control.removeAt(index);
  }

  fillInvoice(data) {
    while (this.form.value.invoice.length !== 0) {
      this.deleteFolderFile(0);
    }
    for (let invoice of data) {
      this.addFolderFile(invoice);
    }
  }


  nameUpdate(val, index) {
    this.form.controls.invoice.controls[index].controls.name.setValue(val);
  }



}
