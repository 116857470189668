import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(
    private snackBar: MatSnackBar
  ) { }

  openSnackBar(message) {
    let msg = message['error'] ? message['error']['reason'] ? message['error']['reason'] : (typeof message['error'] == 'string') ? message['error'] : 'Something is wrong' : message['reason'] ? message['reason'] : message;
    return this.snackBar.open(msg, 'close', {
      duration: 3000,
      verticalPosition: 'top',
      horizontalPosition: 'right'
    });
  }

  longToaster(message) {
    return this.snackBar.open(message, 'close', {
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: 'long-popover'
    });
  }

  close() {
    this.snackBar.dismiss();
  }
}
