import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CommonService } from 'src/app/utils/services/common/common.service';
import { SnackbarService } from 'src/app/utils/services/snackbar/snackbar.service';

export interface MatData {
  user: any
}
@Component({
  selector: 'app-block-member',
  templateUrl: './block-member.component.html',
  styleUrls: ['./block-member.component.scss']
})
export class BlockMemberComponent implements OnInit {
  blocked;
  loading: boolean;
  constructor(
    private dialogRef: MatDialogRef<BlockMemberComponent>,
    public common: CommonService,
    private msg: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public data: MatData,
  ) {
    this.blocked = this.data['user']['isBlocked'];
  }

  ngOnInit() {
  }

  blockMember() {
    let data = {
      isBlocked: !this.blocked
    }
    this.loading = true;
    this.common.updateUser(this.data['user']['_id'], data).subscribe(res => {
      this.loading = false;
      if (data.isBlocked) {
        this.msg.openSnackBar('User is blocked successfully');
      }
      else {
        this.msg.openSnackBar('User is unblocked successfully');
      }
      this.dialogRef.close(true);
    }, (err) => {
      this.loading = false;
      this.msg.openSnackBar(err);
    })
  }

  close() {
    this.dialogRef.close()
  }

}
