import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SnackbarService } from 'src/app/utils/services/snackbar/snackbar.service';
import { CommonService } from 'src/app/utils/services/common/common.service';

export interface MatData {
  images: any[],
  bucket: string
}

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit {
  selectedImage: number = 0;
  allImages: any[];
  constructor(
    private dialogRef: MatDialogRef<PreviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MatData,
    private msg: SnackbarService,
    public common: CommonService
  ) {
    this.allImages = this.data['images'];
    console.log(this.allImages)
  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

  prev() {
    if (this.selectedImage != 0) {
      this.selectedImage = this.selectedImage - 1;
    }
  }

  next() {
    if (this.selectedImage != (this.data['images'].length - 1)) {
      this.selectedImage = this.selectedImage + 1;
    }
  }

  download(doc, name, type?) {
    let newName = name + '-' + type;
    this.common.download(doc.image.file, this.data['bucket'], newName);
  }

}
