import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'address'
})
export class AddressPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    let val = `${value.line1}${value.line2 ? ', ' : ''}${value.line2}, ${value.city}, ${value.state}, ${value.country}, ${value.zipcode}`
    return val;
  }

}
