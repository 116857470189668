import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'ago'
})
export class AgoPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    let val = moment(value).fromNow();
    return val;
  }

}
