import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PreviewComponent } from '../../clients/preview/preview.component';
import { MatDialog } from '@angular/material';
import { FileSystemDirectoryEntry, FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { getModules, checkPermission } from 'src/app/utils/common';
import { SnackbarService } from 'src/app/utils/services/snackbar/snackbar.service';

@Component({
  selector: 'app-document-card',
  templateUrl: './document-card.component.html',
  styleUrls: ['./document-card.component.scss']
})
export class DocumentCardComponent implements OnInit {

  @Input() name: string;
  @Input() src: string;
  @Input() showEdit: string;
  @Input() fileType: string;
  @Input() loading: boolean;
  @Input() bucket: string;
  @Input() file: string;
  @Input() permission: string;
  @Input() nameUpdate: string;
  @Input() folderType: string;
  @Input() cantUpload: string;
  @Input() fileName: string;

  @Output() upload = new EventEmitter();
  @Output() download = new EventEmitter();
  @Output() workingFile = new EventEmitter();
  @Output() nameValue = new EventEmitter();
  @Output() showFolderModal = new EventEmitter();
  modules = getModules();

  constructor(
    private dialog: MatDialog,
    private msg: SnackbarService
  ) { }

  ngOnInit() {
  }

  preview() {
    let imgObj = {
      name: this.name,
      sub: this.fileType,
      image: {
        file: this.file,
        preview: this.src
      }
    };
    let dialogRef = this.dialog.open(PreviewComponent, {
      autoFocus: false,
      panelClass: 'preview-modal',
      width: '100%',
      height: '100%',
      disableClose: true,
      data: {
        images: [imgObj],
        bucket: this.bucket
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // this.getClients();
      }
    });
  }

  dropped(files: NgxFileDropEntry[]) {
    let permission = this.permission ? this.permission : 'documents.update';
    if (checkPermission(permission)) {
      for (const droppedFile of files) {

        // Is it a file?
        if (!this.folderType) {
          if (droppedFile.fileEntry.isFile) {
            const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
            fileEntry.file((file: File) => {

              // Here you can access the real file
              console.log(droppedFile.relativePath, file);

              this.upload.emit({ image: file });

            });
          } else {
            // It was a directory (empty directories are added, otherwise only files)
            const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
            console.log(droppedFile.relativePath, fileEntry);
          }
        }
        else {
          this.openModal();
        }
      }
    }
    else {
      this.msg.openSnackBar(`You don't have permission to upload documents`);
    }
  }

  downloadDocument() {
    this.download.emit();
  }

  deleteWorkingFile() {
    this.workingFile.emit();
  }

  changeName(event) {
    let val = event.target.value;
    this.nameValue.emit(val);
  }

  openModal() {
    this.showFolderModal.emit();
  }

}
