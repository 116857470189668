import { AbstractControl, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';
// import { Buffer } from 'buffer';
declare const Buffer


export function patternValidator(regexp: RegExp): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
        const value = control.value;
        if (!value) {
            return null;
        }
        return !regexp.test(value) ? { 'patternInvalid': { regexp } } : null;
    };
}

export function validationError(form) {
    Object.keys(form.controls).forEach(field => {
        const control = form.get(field);
        control.markAsTouched({ onlySelf: true });
        if (control.controls) {
            validationError(control);
        }
    });
}




export function clean(obj) {
    for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
            delete obj[propName];
        }
        if (typeof obj[propName] == 'object' && !(obj[propName] instanceof Date)) {
            obj[propName] = clean(obj[propName]);
            if (Object.keys(obj[propName]).length == 0) {
                delete obj[propName];
            }
        }
    }

    return obj;
}


export function getDayName() {
    return ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']
}

export function getMonthName() {
    return ["January", "February", "March", "April", "May", "June", "July",
        "August", "September", "October", "November", "December"]
}

export function getFinanceMonthName() {
    return ["April", "May", "June", "July",
        "August", "September", "October", "November", "December", "January", "February", "March",]
}


export function getTodayHours() {
    let startofDay: any = moment().startOf('day').format("hh:mm A");
    let timesArray: any = [];
    for (let i = 0; i < 24; i++) {
        let value = moment(startofDay, 'hh:mm A').format('HH');
        timesArray.push({ name: startofDay, value: value });
        startofDay = moment(startofDay, 'hh:mm A').add(60, 'minutes').format("hh A");
    }

    return timesArray;
}


export function getNextDate() {
    let date = moment();
    let nextDate = moment(date).add(1, 'days').format('DD-MM-YY HH:mm')
    return nextDate;
}

export function sortByDate(data) {
    return data.sort((a, b) => {
        if (a < b) {            // a comes first
            return -1
        } else if (b < a) {     // b comes first
            return 1
        } else {                // equal, so order is irrelevant
            return 0            // note: sort is not necessarily stable in JS
        }
    })
}


export const defaultPagination = {
    pageNo: 1,
    size: 20
}

export function base64(data) {
    if (Object.keys(data).length > 0) {
        let objJsonStr = JSON.stringify(data);
        let objJsonB64 = Buffer.from(objJsonStr).toString("base64");
        return objJsonB64;
    }
    else {
        return null;
    }

}

export function formatDocType(data) {
    let returnData = [];
    for (let [index, key] of Object.keys({ ...data[data.length - 1] }).entries()) {
        if (key != '_id') {
            returnData.push({
                key: key,
                subType: [],
                docType: []
            })
            if (Object.keys(data[data.length - 1][key]).length > 0) {
                returnData[index - 1]['subType'] = checkSubKey(data[data.length - 1][key], returnData[index - 1]);
            }
        }
    }
    return returnData;
}

export function formatNewDocType(docData) {
    let docType = docData[docData.length - 1];
    let data = groupBy(docType, 'category');
    let returnData = [];
    for (let [index, key] of Object.keys({ ...data }).entries()) {
        returnData.push({
            key: key,
            subType: [],
            docType: []
        })
        if (Object.keys(data[key]).length > 0) {
            returnData[index]['subType'] = checkSubKey(data[key], returnData[index]);
        }
    }

    return returnData;
}

function groupBy(objectArray, property) {
    let data = { ...objectArray }
    let obj: any = {};
    for (let key of Object.keys(data)) {
        if (notCheck.indexOf(key) == -1) {
            if (!obj[objectArray[key]['category']]) {
                obj[objectArray[key]['category']] = {};
            }

            obj[objectArray[key]['category']][key] = objectArray[key];
            delete data[key]['category'];
        }
    }
    return obj;
}

const notCheck = ['point', 'dueDate', 'duration', 'category', 'clientUpload', 'primary', 'createdAt', 'updatedAt', '__v', '_id'];



export function checkSubKey(key, returnData) {
    // console.log(key,returnData)
    let fileType = ['workingSheet', 'challan', 'acknowledgement', 'fvu_file', 'certificate'];
    for (let [index, docKey] of Object.keys(key).entries()) {
        let subKey = docKey;
        if (checkWorkingFolder(docKey)) {
            subKey = returnData.key + '_' + docKey;
        }
        if (notCheck.indexOf(subKey) == -1) {
            if (fileType.indexOf(subKey) == -1) {
                returnData['subType'].push({ key: subKey, subType: [], docType: [], workingFolder: (checkWorkingFolder(docKey)) ? true : false });
                if (Object.keys(key[docKey]).length > 0) {
                    returnData['subType'][index]['subType'] = checkSubKey(key[docKey], returnData['subType'][index]);
                }
            }
            else {
                returnData['docType'].push({ key: subKey, subType: [] });
            }
        }
    }

    return returnData['subType'];
}


export function allFinancialYear() {
    var currentYear = new Date().getFullYear(), years = [];
    let startYear = 2016;
    while (startYear <= currentYear) {
        years.push(`${startYear}-${startYear + 1}`);
        startYear++;
    }
    return years;
}

export const fileTypes = ['workingSheet', 'challan', 'acknowledgement', 'fvu_file', 'certificate'];


export function getModules() {
    let modules = JSON.parse(localStorage.getItem('modules'));
    return modules;
}

export function checkPermission(permission) {
    let modules = getModules();
    // console.log(modules)
    if (modules.indexOf(permission) >= 0) {
        return true;
    }
    else if (modules.indexOf(`${permission}.y`) != -1) {
        return true;
    }
    else {
        return false;
    }
}


export const activityTime = [
    // { key: '8-9', time: 'AM' },
    // { key: '9-10', time: 'AM' },
    { key: '10-11', time: 'AM' },
    { key: '11-12', time: 'AM' },
    { key: '12-1', time: 'PM' },
    { key: '1-2', time: 'PM' },
    { key: '2-3', time: 'PM' },
    { key: '3-4', time: 'PM' },
    { key: '4-5', time: 'PM' },
    { key: '5-6', time: 'PM' },
    { key: '6-7', time: 'PM' },
    { key: '7-8', time: 'PM' },
]


const isWorkingFolder = ['WORKING_FOLDER', 'CHALLANS']

export function checkWorkingFolder(type) {
    if (type.includes('FOLDER')) {
        return true;
    }
    else {
        // if (type.includes('TDS')) {
        //     return true;
        // }
        // else {
        return false;
        // }
    }
}

export function checkWorkingFolderForClient(type) {
    if (type.includes('FOLDER')) {
        return true;
    }
    else {
        if (type.includes('TDS')) {
            return true;
        }
        else {
            return false;
        }
    }
}


export function removeExtension(filename) {
    let formattedVal = filename.split('.').slice(0, -1).join('.');
    return formattedVal;
}