import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { clean, getModules } from '../../common';
import { saveAs } from 'file-saver';
import * as mime from 'mime';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private http: HttpClient
  ) { }



  // authentication

  login(data): Observable<any> {
    return this.http.post(`${environment.API_URL}/auth/login`, data);
  }

  forgotPassword(data): Observable<any> {
    return this.http.post(`${environment.API_URL}/auth/forgot`, data);
  }

  resetPassword(data, params): Observable<any> {
    return this.http.post(`${environment.API_URL}/auth/resetPassword`, data, { params: params });
  }

  // verifyToken(token): Observable<any> {
  //   return this.http.get(`${environment.API_URL}/auth/reset/${token}`);
  // }

  // user or client 

  getUsers(params?): Observable<any> {
    return this.http.get(`${environment.API_URL}/users`, { params: clean(params) });
  }

  getUserProfile(id): Observable<any> {
    return this.http.get(`${environment.API_URL}/users/${id}`);
  }

  addUser(data): Observable<any> {
    return this.http.post(`${environment.API_URL}/users`, data);
  }

  updateUser(id, data): Observable<any> {
    return this.http.post(`${environment.API_URL}/users/${id}`, data);
  }

  deleteUser(id): Observable<any> {
    return this.http.delete(`${environment.API_URL}/users/${id}`);
  }

  getRoles() {
    return this.http.get(`${environment.API_URL}/roles`);
  }

  assignMemberToClient(id, data) {
    return this.http.post(`${environment.API_URL}/users/${id}`, data);
  }


  // documents


  getDocuments(params?): Observable<any> {
    return this.http.get(`${environment.API_URL}/documents`, { params: clean(params) });
  }

  documentDetails(id): Observable<any> {
    return this.http.get(`${environment.API_URL}/documents/${id}`);
  }

  uploadDocument(data): Observable<any> {
    return this.http.post(`${environment.API_URL}/documents`, data);
  }

  updateDocument(id, data): Observable<any> {
    return this.http.put(`${environment.API_URL}/documents/${id}`, data);
  }

  deleteDocument(id): Observable<any> {
    return this.http.delete(`${environment.API_URL}/documents/${id}`);
  }

  // getDocumentFile(id): Observable<any> {
  //   return this.http.get(`${environment.API_URL}/documents/${id}/file`);
  // }

  // getDocumentPreview(id): Observable<any> {
  //   return this.http.get(`${environment.API_URL}/documents/${id}/preview`);
  // }

  getDocumentOfClient(id, params?): Observable<any> {
    return this.http.get(`${environment.API_URL}/documents/GetDocumentByClient/${id}`, { params: clean(params) });
  }


  getDocType() {
    return this.http.get(`${environment.API_URL}/documentMaster`);

  }



  //upload image

  uploadImage(data, bucket): Observable<any> {
    return this.http.post(`${environment.API_URL}/files/upload`, data, { params: { bucket: bucket } });
  }

  // payments

  getPayments(params?): Observable<any> {
    return this.http.get(`${environment.API_URL}/paymentRequests`, { params: clean(params) });
  }

  deletePayment(id): Observable<any> {
    return this.http.delete(`${environment.API_URL}/paymentRequests/${id}`);
  }

  addPaymentReminder(data): Observable<any> {
    return this.http.post(`${environment.API_URL}/paymentRequests`, data);
  }
  updatePaymentReminder(id, data): Observable<any> {
    return this.http.post(`${environment.API_URL}/paymentRequests/${id}`, data);
  }




  download(file, bucket, name?) {
    return this.http.post(`${environment.API_URL}/files/download`, { url: file, bucketName: bucket }, { responseType: 'arraybuffer' }).subscribe(res => {
      let mimeType = mime.getType(file);
      var blob = new Blob([res], { type: mimeType });
      saveAs(blob, name)
    }, (err) => {
      console.log(err);
    })
  }


  // dashboard

  getClientCounts(params?): Observable<any> {
    return this.http.get(`${environment.API_URL}/users/countClients`, { params: clean(params) });
  }

  getDocumentCounts(params?): Observable<any> {
    return this.http.get(`${environment.API_URL}/documents/countDocuments`, { params: clean(params) });
  }
  getPaymentCounts(params?): Observable<any> {
    return this.http.get(`${environment.API_URL}/paymentRequests/countPayments`, { params: clean(params) });
  }

  paymentTreds(params?): Observable<any> {
    return this.http.get(`${environment.API_URL}/paymentRequests/payments/graphs`, { params: clean(params) });
  }

  defaultingClients(params?): Observable<any> {
    return this.http.get(`${environment.API_URL}/paymentRequests/defaulting/clients`, { params: clean(params) });
  }

  //tasks

  pendingTasks(params?): Observable<any> {
    return this.http.get(`${environment.API_URL}/documents/pending/task`, { params: clean(params) });
  }

  todaysActivity(params?): Observable<any> {
    return this.http.get(`${environment.API_URL}/documents/Today/Activities`, { params: clean(params) });
  }

  graphGet(params?): Observable<any> {
    return this.http.get(`${environment.API_URL}/paymentRequests/monthlyWeekly/graphs?type=monthly`, { params: clean(params) });
  }

  checkPermission(permission) {
    let modules = getModules();
    if (modules && modules.length > 0) {
      if (modules.indexOf(permission) >= 0) {
        return true;
      }
      else if (modules.indexOf(`${permission}.y`) != -1) {
        return true;
      }
      else {
        return false;
      }
    }
  }


  checkType(type, active, stakeholder) {
    if (!stakeholder) {
      return type == active;
    }
    else {
      let newActive = active.replace(`-${stakeholder.name}`, '')
      return newActive == type;
    }
  }

}
