import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatInputModule,
  MatFormFieldModule,
  MatSidenavModule,
  MatListModule,
  MatDialogModule,
  MatMenuModule,
  MatSelectModule,
  MatDatepickerModule,
  MatGridListModule,
  MatSliderModule,
  MatIconModule,
  MatTableModule,
  MatPaginatorModule,
  MatCheckboxModule,
  MatTooltipModule,
  MatSnackBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatTabsModule,
  MatChipsModule,
  MatSlideToggleModule,
  MatStepperModule,
  MatProgressBarModule,
  MatExpansionModule,
  MatTreeModule
} from '@angular/material';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SafePipe } from '../pipes/safe/safe.pipe';
import { CapitalPipe } from '../pipes/capital/capital.pipe';
import { AddressPipe } from '../pipes/address/address.pipe';
import { DocumentCardComponent } from 'src/app/dashboard/components/document-card/document-card.component';
import { PreviewComponent } from 'src/app/dashboard/clients/preview/preview.component';
import { LogoutComponent } from 'src/app/dashboard/components/logout/logout.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { ActionIconComponent } from 'src/app/dashboard/components/action-icon/action-icon.component';
import { AddReminderComponent } from 'src/app/dashboard/payments/add-reminder/add-reminder.component';
import { DeletePaymentComponent } from 'src/app/dashboard/payments/delete-payment/delete-payment.component';
import { AssignMembersComponent } from 'src/app/dashboard/clients/assign-members/assign-members.component';
import { BlockMemberComponent } from 'src/app/dashboard/settings/team/block-member/block-member.component';
import { ChartModule } from 'angular-highcharts';
import { AssignedPipe } from '../pipes/assigned/assigned.pipe';
import { DocNamePipe } from '../pipes/doc-name/doc-name.pipe';
import { SkeletonComponent } from 'src/app/dashboard/components/skeletons/skeleton/skeleton.component';
import { SkeletonElementComponent } from 'src/app/dashboard/components/skeletons/skeleton-element/skeleton-element.component';
import { AgoPipe } from '../pipes/ago/ago.pipe';
import { DocNameLengthPipe } from '../pipes/doc-name-length/doc-name-length.pipe';
import { FileNamePipe } from '../pipes/file-name/file-name.pipe';
import { FolderUploadComponent } from 'src/app/dashboard/components/folder-upload/folder-upload.component';
import { BulkUploadComponent } from 'src/app/dashboard/components/bulk-upload/bulk-upload.component';


@NgModule({
  declarations: [
    SafePipe,
    CapitalPipe,
    AddressPipe,
    DocumentCardComponent,
    PreviewComponent,
    LogoutComponent,
    ActionIconComponent,
    AddReminderComponent,
    DeletePaymentComponent,
    AssignMembersComponent,
    BlockMemberComponent,
    AssignedPipe,
    DocNamePipe,
    SkeletonComponent,
    SkeletonElementComponent,
    AgoPipe,
    FileNamePipe,
    DocNameLengthPipe,
    FolderUploadComponent,
    BulkUploadComponent
  ],
  imports: [
    CommonModule,
    MatInputModule,
    MatFormFieldModule,
    MatSidenavModule,
    MatListModule,
    MatDialogModule,
    MatMenuModule,
    MatSelectModule,
    MatDatepickerModule,
    MatGridListModule,
    MatSliderModule,
    MatIconModule,
    MatTableModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatTabsModule,
    MatChipsModule,
    MatSlideToggleModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatStepperModule,
    MatProgressBarModule,
    MatExpansionModule,
    NgxFileDropModule,
    ChartModule,
    MatTreeModule
  ],
  exports: [
    MatInputModule,
    MatFormFieldModule,
    MatSidenavModule,
    MatListModule,
    MatDialogModule,
    MatMenuModule,
    MatSelectModule,
    MatDatepickerModule,
    MatGridListModule,
    MatSliderModule,
    MatIconModule,
    MatTableModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    HttpClientModule,
    MatRadioModule,
    MatTabsModule,
    MatChipsModule,
    MatSlideToggleModule,
    FormsModule,
    ReactiveFormsModule,
    SafePipe,
    CapitalPipe,
    AddressPipe,
    MatStepperModule,
    MatProgressBarModule,
    DocumentCardComponent,
    MatExpansionModule,
    PreviewComponent,
    LogoutComponent,
    NgxFileDropModule,
    ActionIconComponent,
    AddReminderComponent,
    DeletePaymentComponent,
    AssignMembersComponent,
    BlockMemberComponent,
    ChartModule,
    AssignedPipe,
    SkeletonComponent,
    SkeletonElementComponent,
    DocNamePipe,
    AgoPipe,
    DocNameLengthPipe,
    FileNamePipe,
    MatTreeModule,
    FolderUploadComponent,
    BulkUploadComponent
  ],
  entryComponents: [
    PreviewComponent,
    LogoutComponent,
    AddReminderComponent,
    DeletePaymentComponent,
    AssignMembersComponent,
    BlockMemberComponent,
    FolderUploadComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class SharedModule { }
