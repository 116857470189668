import { Component, OnInit, Inject } from '@angular/core';
import { SnackbarService } from 'src/app/utils/services/snackbar/snackbar.service';
import { CommonService } from 'src/app/utils/services/common/common.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export interface MatData {
  payment
}

@Component({
  selector: 'app-delete-payment',
  templateUrl: './delete-payment.component.html',
  styleUrls: ['./delete-payment.component.scss']
})
export class DeletePaymentComponent implements OnInit {
  loading: boolean;
  constructor(
    private dialogRef: MatDialogRef<DeletePaymentComponent>,
    public common: CommonService,
    private msg: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public data: MatData,
  ) { }

  ngOnInit() {
  }


  close() {
    this.dialogRef.close();
  }

  deletePayment() {
    this.loading = true;
    this.common.deletePayment(this.data['payment']['_id']).subscribe(res => {
      this.loading = false;
      this.msg.openSnackBar('Payment is deleted successfully');
      this.dialogRef.close(true);
    }, (err) => {
      this.loading = false;
      this.msg.openSnackBar(err);
    })
  }
}
