import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'docNameLength'
})
export class DocNameLengthPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    let finalVal = '';
    let val = value.split('_');
    let splitIndex = Math.floor(val.length / 2);
    for (let [i, key] of val.entries()) {
      if (finalVal) {
        if (i != splitIndex) {
          finalVal = finalVal + ' ' + key;
        }
        else {
          finalVal = finalVal + ' ' + key;
        }
      }
      else {
        finalVal = key;
      }
    }
    return finalVal;
  }

}
