import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormArray } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CommonService } from 'src/app/utils/services/common/common.service';
import { SnackbarService } from 'src/app/utils/services/snackbar/snackbar.service';
import { FileSystemDirectoryEntry, FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { checkPermission, removeExtension } from 'src/app/utils/common';


export interface MatData {
  doc: any
}

@Component({
  selector: 'app-folder-upload',
  templateUrl: './folder-upload.component.html',
  styleUrls: ['./folder-upload.component.scss']
})
export class FolderUploadComponent implements OnInit {
  form;
  loading: boolean;
  bulkLoading: boolean = false;
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<FolderUploadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MatData,
    private common: CommonService,
    private msg: SnackbarService
  ) { }

  ngOnInit() {
    this.createForm();
    if (this.data['doc'] && this.data['doc']['folder']) {
      this.fillWorkingFolder(this.data['doc']);
    }
  }
  createForm() {
    this.form = this.fb.group({
      folder: this.fb.array([
        // this.fb.group({
        //   file: [''],
        //   preview: [''],
        //   name: [''],
        //   loading: [false]
        // })
      ])
    })
  }

  addFolderFile(doc?) {
    let control = <FormArray>this.form.controls.folder;
    control.push(
      this.fb.group({
        file: [doc ? doc.file : ''],
        preview: [doc ? doc.preview : ''],
        name: [doc ? doc.name : ''],
        loading: [false]
      })
    );
  }

  deleteFolderFile(index) {
    let control = <FormArray>this.form.controls.folder;
    control.removeAt(index);
  }

  nameUpdate(val, index) {
    this.form.controls.folder.controls[index].controls.name.setValue(val);
  }

  close() {
    this.dialogRef.close();
  }

  uploadImage(data, control, val) {
    this.setFileLoading(val, true);
    let formData = new FormData();
    formData.append('payload', data.image);

    this.common.uploadImage(formData, 'hma-docs').subscribe(res => {
      this.setFileLoading(val, false);
      if (res[0]) {
        control.controls.file.setValue(res[0]['file']['location']);
        control.controls.preview.setValue(res[0]['file']['location']);
        if (control.controls.name) {
          control.controls.name.setValue(removeExtension(res[0]['file']['name']));
        }
      }
    }, (err) => {
      this.setFileLoading(val, false);
    })
  }

  setFileLoading(val, setVal) {
    this.form.controls.folder.controls[val].controls.loading.setValue(setVal);
  }

  updateApi(id, formData) {
    delete formData.savedDocuments;
    this.common.updateDocument(id, formData).subscribe(res => {
      this.loading = false;
      this.msg.openSnackBar('Document is updated successfully');
      this.dialogRef.close(true);
    }, (err) => {
      this.loading = false;
      this.msg.openSnackBar(err);
    })
  }

  uploadDocument(data) {
    let formData = { ...data };
    formData.folder = data.folder.map(element => {
      return {
        file: element.file,
        preview: element.preview,
        name: element.name
      }
    });
    this.updateApi(this.data['doc']['id'], formData);
  }

  fillWorkingFolder(data) {
    if (data.folder) {
      // this.form.addControl('folder', this.fb.array([
      // ]));
      if (data.folder.length > 0) {
        while (this.form.value.folder.length !== 0) {
          this.deleteFolderFile(0);
        }
      }
      for (let doc of data.folder) {
        this.addFolderFile(doc);
      }
    }
  }



  uploadBulkImages(files) {
    this.bulkLoading = true;
    let formData = new FormData();
    for (let file of files) {
      console.log(file);
      formData.append('payload', file);
    }

    this.common.uploadImage(formData, 'hma-docs').subscribe(res => {
      this.bulkLoading = false;
      let docs = res.map(element => {
        return {
          file: element['file']['location'],
          preview: element['file']['location'],
          name: removeExtension(element['file']['name'])
        }
      })
      this.createDocumentBoxes(docs);
    }, (err) => {
      this.bulkLoading = false;
    })
  }

  createDocumentBoxes(docs) {
    for (let doc of docs) {
      this.addFolderFile(doc);
    }
  }

  download(file, name) {
    this.common.download(file, 'hma-docs', name);
  }

}
